<template>
  <Loading v-if="isLoading" />
  <div v-else class="patient-pages">
    <PageTitle icon="fa fa-user" main="Pacientes" sub />
    <div class="admin-pages-tabs">
      <b-button size="sm" variant="success" @click="newPatient()">
        NOVO
      </b-button>
      <b-button size="sm" class="ml-3" @click="print" variant="info" v-b-popover.hover.top="'Imprimir Listagem'">
        <div class="d-flex align-items-center">
          <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
          <span>{{ textBtnPrint }}</span>
        </div>
      </b-button>
      <hr>
      <b-row>
        <b-col md="3" sm="12" class="mt-2">
          <b-form-input size="sm" placeholder="Filtrar..." v-model="ftext"></b-form-input>
        </b-col>
        <b-col md="1" sm="12" class="mt-2">
          <b-button size="sm" @click="loadPatients()"><i class="fa fa-search-plus"></i></b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="12" class="mt-3">
          <b-form-checkbox v-model="withoutCovenant" :value="true" :unchecked-value="false">Sem informações de
            convênio</b-form-checkbox>
        </b-col>
      </b-row>
      <hr>
      <b-table ref="selectableTable" head-variant="light" outlined hover :items="patients" :fields="fields"
        responsive="sm" id="my-table" :per-page="perPage" :current-page="currentPage" striped fixed :filter="ftext">
        <template v-slot:cell(actions)="row">
          <b-button size="sm" variant="warning" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Alterar'">
            <i class="fa fa-pencil"></i>
          </b-button>

          <b-button class="ml-2" size="sm" variant="info" @click="historicAttendance(row.item)"
            v-b-popover.hover.top="'Histórico Atendimentos'">
            <i class="fa fa-list"></i>
          </b-button>

          <b-button class="ml-2" size="sm" variant="primary" @click="getResume(row.item)"
            v-b-popover.hover.top="'Fichas e Avaliações'">
            <i class="fa fa-file-text"></i>
          </b-button>

          <b-button v-if="row.item.hospitalized" class="ml-2" size="sm" variant="secondary"
            @click="setRelease(row.item)" v-b-popover.hover.top="'Alta'">
            <i class="fa fa-sign-out"></i>
          </b-button>

          <b-button v-if="!row.item.disable" class="ml-2" size="sm" variant="danger" @click="disablePatient(row.item)" v-b-popover.hover.top="'Desabilitar'">
            <i class="fa fa-minus-circle"></i>
          </b-button>          
        </template>
      </b-table>

      <b-modal size="lg" ok-only v-model="modalHistoricAttendance" :title="patientSelected.name">
        <b-table head-variant="light" outlined hover :fields="fieldsHistoricAttendance" responsive="sm"
          id="my-table-historicAttendance" :items=patientsHistoric :per-page="perPageHistoricAttendance"
          :current-page="currentPageHistoricAttendance">
        </b-table>

        <b-pagination pills v-model="currentPageHistoricAttendance" :total-rows="rowsHistoricAttendance"
          :per-page="perPageHistoricAttendance" aria-controls="my-table-historicAttendance"
          class="page-item-historicAttendance">
        </b-pagination>
      </b-modal>

      <div v-if="isLoading" class="d-flex justify-content-center mb-3">
        <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
      </div>

      <b-row class="ml-1">
          <b-pagination size="sm" pills v-model="currentPage" :total-rows="rows" :per-page="perPage"
            aria-controls="my-table" class="page-item">
          </b-pagination>        
          <h5 class="badge2"><b-badge class="ml-3" pill variant="danger">Desabilitados</b-badge></h5>
      </b-row>      
    </div>
  </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'
import Loading from "../../template/Loading"

export default {
  name: 'Patients',
  components: { PageTitle, Loading },
  data: function () {
    return {
      isLoading: false,
      generatingPDF: false,
      textBtnPrint: 'IMPRIMIR',
      modalHistoricAttendance: false,
      currentPageHistoricAttendance: 1,
      perPageHistoricAttendance: 10,
      ftext: '',
      patients: [],
      patientSelected: {},
      patientsHistoric: [],
      withoutCovenant: false,
      currentPage: 1,
      perPage: 10,
      fields: [
        { key: 'name', label: 'Nome', sortable: true },
        { key: 'cpf', label: 'CPF', sortable: true },
        { key: 'rg', label: 'RG', sortable: true },
        { key: 'covenant.name', label: 'Convênio', sortable: true },
        { key: 'email', label: 'E-mail', sortable: true },
        { key: 'actions', label: 'Ações' }
      ],
      fieldsHistoricAttendance: [
        { key: 'professional', label: 'Profissional', sortable: true },
        { key: 'attendanceNumber', label: 'Nº Atendimento', sortable: true },
        { key: 'date', label: 'Data', sortable: true },
        { key: 'start', label: 'Início', sortable: true },
        { key: 'finish', label: 'Término', sortable: true },
      ]
    }
  },
  computed: {
    rows() {
      return this.patients.length
    },
    rowsHistoricAttendance() {
      return this.patientsHistoric.length
    }
  },
  methods: {
    print() {
      this.generatingPDF = true
      this.textBtnPrint = 'Aguarde...'

      let contentPdf = {
        title: 'PACIENTES',
        format: 'A4',
        orientation: 'landscape',
        separate: false,
        content: [
          {
            type: 'table',
            topicFields: [],
            fields: [
              {
                original: 'name',
                exibition: 'Nome'
              },
              {
                original: 'cpf',
                exibition: 'CPF'
              },
              {
                original: 'covenant.name',
                exibition: 'Convênio'
              },
              {
                original: 'email',
                exibition: 'Email'
              },
              {
                original: 'phone1',
                exibition: 'Fone'
              },
            ],
            list: this.patients
          }
        ]
      }

      this.isLoading = true

      const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
      axios.post(url, contentPdf, { responseType: 'arraybuffer' }).then(res => {
        var file = new Blob([res.data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);

        this.generatingPDF = false
        this.textBtnPrint = 'IMPRIMIR'
        this.isLoading = false
      }).catch(e => {
        this.isLoading = false
        this.$toasted.global.defaultError({ msg: e.response.data })
      })
    },
    newPatient() {
      this.$router.push({ name: 'patient', params: {}, })
    },
    onRowSelected(itemSelected) {
      let patientSelected = {
        _id: itemSelected._id,
        name: itemSelected.name,
        birthday: itemSelected.birthday,
        gender: itemSelected.gender,
        cpf: itemSelected.cpf,
        rg: itemSelected.rg,
        email: itemSelected.email,
        phone1: itemSelected.phone1,
        phone2: itemSelected.phone2,
        address: itemSelected.address,
        comments: itemSelected.comments,
        responsible: itemSelected.responsible,
        covenant: itemSelected.covenant,
        password: itemSelected.password,
        emailValidated: itemSelected.emailValidated,
        strValidated: itemSelected.strValidated,
        questions: itemSelected.questions,
        socialName: itemSelected.socialName,
        documents: itemSelected.documents,
        foreigner: itemSelected.foreigner,
        disable: itemSelected.disable
      }

      this.$router.push({ name: 'patient', params: { patientSelected }, })
    },
    historicAttendance(itemSelected) {
      this.isLoading = true

      this.patientSelected = itemSelected

      const url = `${baseApiUrl}/scale/patient/${itemSelected._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

      Moment.locale('pt-br')

      this.patientsHistoric = []

      axios.get(url).then(res => {
        this.isLoading = false

        if (res.data.length > 0) {
          for (let r of res.data) {
            for (let pr of r.professionals) {
              if (pr) {
                if (pr.attendance) {
                  if (Array.isArray(pr.attendance)) {
                    for (let a of pr.attendance) {
                      for (let pa of a.patients) {
                        if (pa) {
                          if (pa._id == this.patientSelected._id) {
                            this.patientsHistoric.push({
                              date: Moment(r.date).format('L'),
                              professional: pr.name,
                              attendanceNumber: pa.admissionAttendanceNumber,
                              accommodation: pa.accommodation == undefined ? '' :
                                pa.accommodation.building + ' -> ' +
                                pa.accommodation.floor + ' -> ' +
                                pa.accommodation.apartment + ' -> ' +
                                pa.accommodation.name,
                              start: pa.startDone ? pa.startDone : pa.start,
                              finish: pa.finishDone ? pa.finishDone : pa.finish,
                              _cellVariants: {
                                date: pa.startDone ? 'success' : '',
                                professional: pa.startDone ? 'success' : '',
                                attendanceNumber: pa.startDone ? 'success' : '',
                                accommodation: pa.startDone ? 'success' : '',
                                start: pa.startDone ? 'success' : '',
                                finish: pa.startDone ? 'success' : ''
                              }
                            })
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          this.modalHistoricAttendance = !this.modalHistoricAttendance
        }
      }).catch(e => {
        this.isLoading = false
        this.$toasted.global.defaultError({ msg: e.response.data })
      })
    },
    async loadPatients() {
      if (this.ftext == "" && !this.withoutCovenant) {
        this.$toasted.global.defaultError({ msg: 'Insira alguma informação para consultar.' })
      } else {
        this.isLoading = true

        let url = ''

        if (!this.withoutCovenant) {
          url = `${baseApiUrl}/patient/perNameOrCPF/${this.ftext.toUpperCase() ? this.ftext.toUpperCase().trim() : null}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
        } else {
          url = `${baseApiUrl}/patient/perNameOrCPFWithoutCovenant/${this.ftext.toUpperCase() ? this.ftext.toUpperCase().trim() : null}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
        }

        axios.get(url).then(res => {
          if (res.data) {
            this.getAdmissionPatient(res.data)            
          }
        }).catch(e => {
          this.isLoading = false
          this.$toasted.global.defaultError({ msg: e.response.data })
        })
      }
    },
    async getAdmissionPatient(data) {
      this.patients = []

      for (let d of data) {
        let url = ''

        let notCPFAndNumberCovenant = false

        if (!d.cpf && !d.covenant.number) {
          notCPFAndNumberCovenant = true
        }

        if (d.cpf) {
          url = `${baseApiUrl}/admission/cpfnotreleased/CPF&${d.cpf}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
        } else {
          url = `${baseApiUrl}/admission/cpfnotreleased/NUMBERCOVENANT&${d.covenant.number}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
        }

        d._cellVariants = {
          name: d.disable != null ? 'danger' : (d.requester ? 'info' : ''),
          cpf: d.disable != null ? 'danger' : (d.requester ? 'info' : ''),
          rg: d.disable != null ? 'danger' : (d.requester ? 'info' : ''), 
          'covenant.name': d.disable != null ? 'danger' : (d.requester ? 'info' : ''), 
          email: d.disable != null ? 'danger' : (d.requester ? 'info' : ''),
          actions: d.disable != null ? 'danger' : (d.requester ? 'info' : ''),
        }

        if (!notCPFAndNumberCovenant) {
          await axios.get(url).then(resA => {
            if (resA.data[0]) {
              d.hospitalized = true
              d.idAdmission = resA.data[0]._id

              this.patients.push(d)
            } else {
              this.patients.push(d)
            }
          }).catch(e => {
            this.isLoading = false
            this.$toasted.global.defaultError({ msg: e.response.data })
          })
        } else {
          d.hospitalized = false
          this.patients.push(d)
        }
      }

      this.isLoading = false
    },
    disablePatient(itemSelected) {
            this.$bvModal.msgBoxConfirm('Deseja desabilitar o paciente?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {                
                  const id = itemSelected._id

                  this.isLoading = true

                  axios.delete(`${baseApiUrl}/patient/${id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(() => {
                    this.isLoading = false
                    this.$toasted.global.defaultSuccess()
                    this.loadPatients()
                  }).catch(e => {
                    this.isLoading = false
                    this.$toasted.global.defaultError({ msg: e.response.data })
                  })                
                }                
            }).catch()   
        },     
    setRelease(itemSelected) {
      this.$bvModal.msgBoxConfirm('Deseja efetuar a alta do paciente?', {
        title: 'Confirmação',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if (value) {
          const method = 'post'

          let filterSelected
          let information

          if (itemSelected.cpf) {
            filterSelected = 'CPF'
            information = itemSelected.cpf
          } else {
            filterSelected = 'Nome'
            information = itemSelected.name
          }

          const url = `${baseApiUrl}/admission/notreleased/${filterSelected}&${information}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
          axios.get(url).then(res => {
            let admissionId = ''
            let idPatient = ''

            for (let a of res.data) {
              if (!a.released) {
                admissionId = a._id
                idPatient = a.patient._id
              }
            }

            this.isLoading = true

            axios[method](`${baseApiUrl}/admission/patient/${idPatient}&${admissionId}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(() => {
              this.isLoading = false
              this.$toasted.global.defaultSuccess({ msg: 'Alta realizada com sucesso' })
              this.loadPatients()
            }).catch(e => {
              this.isLoading = false
              this.$toasted.global.defaultError({ msg: e.response.data })
            })
          })
        }
      })
    },
    getResume(itemSelected) {
      this.$store.commit('setResultPatient', itemSelected)
      this.$router.push({ name: 'patientResume', params: { patient: itemSelected } })
    }
  },
}
</script>

<style>
.page-item .page-font {
  color: #44A08D;
}

.page-item.active .page-link {
  background-color: #44A08D;
  border-color: #44A08D;
}

.page-item.active .page-link:hover {
  background-color: #44A08D;
  border-color: #44A08D;
}

.card-header {
  height: 38px;
  font-size: 14px;
}

.card-body {
  font-size: 14px;
}

.calendar-btn {
  font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>