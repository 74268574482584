<template>
    <Loading v-if="isLoading" />
    <div v-else class="patient-admin">
        <PageTitle icon="fa fa-user" main="Cadastro de Pacientes" sub />                  
        <b-form>            
            <input id="patient-id" type="hidden" v-model="patient.id"/>
            <b-row>
                <b-col md="6" sm="6">
                    <b-card :class="`card-${theme}`" bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-form-group label="Nome:" label-for="patient-name">
                            <b-form-input size="sm" id="patient-name" type="text" 
                                v-model="patient.name" required                       
                                placeholder="Informe o Nome do Paciente..." />
                        </b-form-group>         
                        <b-row>
                            <b-col md="6" sm="6">
                                <b-form-group label="CPF:" label-for="patient-cpf">
                                    <b-form-input size="sm" id="patient-cpf" type="number" 
                                        v-model="patient.cpf" required
                                        placeholder="Informe o CPF do Paciente..." />
                                </b-form-group> 
                                <b-form-group label="Data Nascimento:" label-for="patient-birthday">
                                    <b-form-input size="sm" id="patient-birthday" type="date"
                                        v-model="patient.birthday" required
                                        placeholder="Informe a Data de Nascimento do Paciente..." />
                                </b-form-group>         
                                <b-form-group label="E-mail:" label-for="patient-email">
                                    <b-form-input size="sm" id="patient-email" type="text"
                                        v-model="patient.email" 
                                        placeholder="Informe o E-mail do Paciente..." />
                                </b-form-group>        
                                <b-form-group label="Fone 1:" label-for="patient-phone1">
                                    <b-form-input size="sm" id="patient-phone1" type="number"
                                        v-model="patient.phone1" 
                                        placeholder="Informe o Fone do Paciente..." />
                                </b-form-group>                              
                            </b-col>
                            <b-col md="6" sm="6">
                                <b-form-group label="RG:" label-for="patient-rg">
                                    <b-form-input size="sm" id="patient-rg" type="number"
                                        v-model="patient.rg" 
                                        placeholder="Confirme o RG do Paciente..." />
                                </b-form-group>
                                <b-form-group label="Sexo:" label-for="patient-gender">
                                    <b-form-select size="sm" v-model="patient.gender" :options="genders"></b-form-select>
                                </b-form-group>        
                                <b-form-group label="Dados Convênio:" label-for="patient-covenant">  
                                  <b-dropdown size="sm" id="dropdown-form-covenant" text="Dados do Convênio do Cliente" ref="dropdownCovenant" variant="light">
                                    <b-dropdown-form>
                                        <b-form-group label="Convênio:" label-for="patient-covenant">
                                            <b-form-select size="sm" v-model="selectedCovenant" :options="covenants"></b-form-select>
                                        </b-form-group>    
                                        <b-form-group label="Número Carteira:" label-for="dropdown-form-number"> 
                                            <b-form-input size="sm" id="dropdown-form-number" placeholder="Número Carteira..." v-model="covenant.number" type="text"></b-form-input> 
                                        </b-form-group>
                                        <b-form-group label="Validade Carteira:" label-for="dropdown-form-valid"> 
                                            <b-form-input size="sm" id="dropdown-form-valid" v-model="covenant.walletValidity" type="date"></b-form-input> 
                                        </b-form-group>
                                    </b-dropdown-form>
                                 </b-dropdown>        
                                </b-form-group>                          
                                <b-form-group label="Fone 2:" label-for="patient-phone2">
                                    <b-form-input size="sm" id="patient-phone2" type="number"
                                        v-model="patient.phone2" 
                                        placeholder="Informe o Fone do Paciente..." />
                                </b-form-group>                                                           
                            </b-col>              
                            <b-col md="12" sm="12">
                                <b-form-group label="Observações:" label-for="patient-comments">
                                    <b-form-textarea size="sm" id="textarea" v-model="patient.comments" placeholder="Observações..." rows="1" max-rows="6"></b-form-textarea>       
                                </b-form-group>                                                                                 
                            </b-col>       
                            <b-col md="4" sm="12">
                                <b-form-group label="Responsável:" label-for="patient-reponsible">
                                    <b-dropdown size="sm" id="dropdown-form-reponsible" text="Dados do Responsável" ref="dropdownResponsible" variant="light">
                                        <b-dropdown-form>
                                            <b-form-group label="Nome:" label-for="dropdown-form-name">
                                                <b-form-input size="sm" id="dropdown-form-name" placeholder="Nome do Responsável..." v-model="responsible.name"></b-form-input>
                                            </b-form-group>
                                            <b-form-group label="CPF:" label-for="dropdown-form-cpf">
                                                <b-form-input size="sm" id="dropdown-form-cpf" placeholder="CPF do Responsável..." v-model="responsible.cpf" type="number"></b-form-input>
                                            </b-form-group>
                                            <b-form-group label="Email:" label-for="dropdown-form-email">
                                                <b-form-input size="sm" id="dropdown-form-email" placeholder="Email do Responsável..." v-model="responsible.email"></b-form-input>
                                            </b-form-group>
                                            <b-form-group label="Relação:" label-for="dropdown-form-kinship">
                                                <b-form-input size="sm" id="dropdown-form-kinship" placeholder="Relação..." v-model="responsible.kinship"></b-form-input>
                                            </b-form-group>                                        
                                        </b-dropdown-form>
                                    </b-dropdown>                                
                                </b-form-group>
                            </b-col>   
                            <b-col md="8" sm="6">
                                <b-form-group label="Nome Social:" label-for="patient-socialName">
                                    <b-form-input size="sm" id="patient-socialName" type="text" 
                                        v-model="patient.socialName"
                                        placeholder="Informe o Nome Social do Paciente..." />
                                </b-form-group> 
                            </b-col>                            
                        </b-row>
                        <b-row>
                            <b-col md="12" sm="12">
                                <b-form-checkbox class="mt-2 mb-2" id="patient-foreigner" v-model="patient.foreigner" name="checkbox-1" 
                                    :value="true" :unchecked-value="false">
                                    Estrangeiro? 
                                </b-form-checkbox>    
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="6" sm="6">
                    <b-card :class="`card-${theme}`" bg-variant="default" header="Endereço" header-bg-variant="dark" header-text-variant="white">
                        <b-row>    
                            <b-col md="5" sm="12"> 
                                <b-form-input size="sm" type="text" id="nested-cep" placeholder="CEP..." v-model="address.cep"></b-form-input>
                            </b-col>
                            <b-col md="1" sm="12"> 
                                <b-button size="sm" @click="searchCEP"><i class="fa fa-search-plus"></i></b-button>
                            </b-col>                            
                        </b-row>
                        <b-row>
                            <b-col md="10" sm="12"> 
                                <b-form-group label="Logradouro:" label-for="nested-street" class="mt-3">
                                    <b-form-input size="sm" id="nested-street" placeholder="Logradouro..." v-model="address.street"></b-form-input>
                                </b-form-group>
                            </b-col>                         
                            <b-col md="2" sm="12"> 
                                <b-form-group label="Número:" label-for="nested-number" class="mt-3">
                                    <b-form-input size="sm" id="nested-number" placeholder="Número..." v-model="address.number"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6" sm="12"> 
                                <b-form-group label="Bairro:" label-for="nested-neighborhood">
                                    <b-form-input size="sm" id="nested-neighborhood" placeholder="Bairro..." v-model="address.neighborhood"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" sm="12"> 
                                <b-form-group label="Município:" label-for="nested-city">
                                    <b-form-input size="sm" id="nested-city" placeholder="Município..." v-model="address.city"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="8" sm="12"> 
                                <b-form-group label="Complemento:" label-for="nested-complement">
                                    <b-form-input size="sm" id="nested-complement" placeholder="Complemento..." v-model="address.complement"></b-form-input>
                                </b-form-group>
                            </b-col>           
                            <b-col md="4" sm="12">                    
                                <b-form-group label="UF:" label-for="patient-state">
                                    <b-form-select size="sm" v-model="address.state" :options="states"></b-form-select>
                                </b-form-group>               
                            </b-col>                                              
                        </b-row>       
                    </b-card>   
                </b-col>                     
            </b-row>
        </b-form>
            
        <b-button size="sm" variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button size="sm" variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>  
    </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import States from '../../default/states'
import Loading from "../../template/Loading"

export default {
    name: "PatientPage",
    components: { PageTitle, Loading },
    data: function() {
      return { 
        theme: null,
        isLoading: false,
        patient: {},
        address: {},
        responsible: {},
        genders: [
            {value: 1, text: "Masculino"},
            {value: 2, text: "Feminino"}
        ],
        selectedCovenant: null,
        covenant: {},
        covenants: [],        
        states: States.states,    
      }    
    },
    methods: {           
        save() {
            this.patient.name = this.patient.name.toUpperCase()

            const method = this.patient._id ? 'put' : 'post'
            const id = this.patient._id ? `/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

            this.patient.address = {
                cep: this.address.cep,
                street: this.address.street,
                number: this.address.number,
                neighborhood: this.address.neighborhood,
                city:  this.address.city,
                complement: this.address.complement,
                state: this.address.state
            }

            this.patient.responsible = this.responsible  
            this.patient.documents = []          

            this.covenants.forEach(c => {
                if (c.value == this.selectedCovenant) {
                    this.patient.covenant = {
                        _id: c.value,
                        name: c.text,
                        number: this.covenant.number,
                        walletValidity: this.covenant.walletValidity
                    }                        
                }
            })

            this.isLoading = true

            axios[method](`${baseApiUrl}/patient${id}`, this.patient).then(() => {
                this.isLoading = false
                this.$toasted.global.defaultSuccess()
                this.$router.go(-1)
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },  
        searchCEP() {     
            this.$viaCep.buscarCep(this.address.cep).then((obj) => {
                this.address = {
                    cep: obj.cep,
                    street: obj.logradouro,
                    neighborhood: obj.bairro,
                    city: obj.localidade,                    
                }                
            });
        },        
        cancel() {
            this.$router.go(-1)
        },
        loadCovenants() {
            this.isLoading = true

            const url = `${baseApiUrl}/covenants/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                for (let r of res.data) {
                    this.covenants.push({ value: r._id,  text: r.fantasyname })                        
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },    
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        if (this.$route.params.patientSelected) {
            this.patient = this.$route.params.patientSelected            
            this.covenant = this.patient.covenant

            if (this.patient.responsible) {
                this.responsible = {
                    name: this.patient.responsible.name,
                    cpf: this.patient.responsible.cpf,
                    email: this.patient.responsible.email,
                    kinship: this.patient.responsible.kinship
                }
            }

            if (this.patient.address) {
                this.address = {
                    cep: this.patient.address.cep,
                    street: this.patient.address.street,
                    number: this.patient.address.number,
                    neighborhood: this.patient.address.neighborhood,
                    city:  this.patient.address.city,
                    complement: this.patient.address.complement,
                    state: this.patient.address.state
                }
            } else {
                this.address = {
                    cep: '',
                    street: '',
                    number: '',
                    neighborhood: '',
                    city:  '',
                    complement: '',
                    state: ''
                }
            }

            this.selectedCovenant = this.covenant._id
        } else {
            const today = new Date();
            const nextYear = new Date(today.setFullYear(today.getFullYear() + 1));
            this.covenant.walletValidity = nextYear.toISOString().split('T')[0];            
        }

        this.loadCovenants()
    }
}     
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>